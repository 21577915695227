import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Porto.',
	[Language.En]: 'Porto.',
}

const SvgLogoLargePath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgLogoLarge: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-logo-large' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={101} height={30} fill="none" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgLogoLargePath
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.91 6.976v7.555h1.94c2.826 0 4.928-.97 4.928-3.879 0-2.868-2.102-3.676-4.929-3.676H7.91zm0 22.261H1V.955h10.22c7.075 0 10.671 4.364 10.671 9.697 0 5.335-3.596 9.9-10.67 9.9H7.909v8.685h.001zm19.444-9.413c0 2.303 1.494 4.041 3.638 4.041 2.1 0 3.636-1.738 3.636-4.041 0-2.343-1.537-4-3.636-4-2.144 0-3.638 1.657-3.638 4zm-6.463 0c0-5.737 4.484-9.86 10.1-9.86 5.617 0 10.1 4.123 10.1 9.86 0 5.737-4.483 9.899-10.1 9.899-5.616 0-10.1-4.162-10.1-9.9v.001zM55.2 15.795h-2.343c-2.79 0-3.517 2.413-3.517 4.798v8.644h-6.465V10.45h6.22v2.99c.39-2.139 1.764-3.058 3.015-3.058.484 0 3.09.068 3.09.068v5.345zM66.366 3.743v6.707h3.76v5.296h-3.76v6.825c0 1.092.93 1.254 1.658 1.254 1.211 0 2.102-.122 2.102-.122v5.534s-3.031.243-4.611.243c-3.029 0-5.615-.848-5.615-4.726v-8.998h-2.786V10.45H59.9V3.743h6.466zm11.587 16.081c0 2.303 1.494 4.041 3.637 4.041 2.1 0 3.636-1.738 3.636-4.041 0-2.343-1.536-4-3.636-4-2.143 0-3.637 1.657-3.637 4zm-6.464 0c0-5.737 4.483-9.86 10.1-9.86 5.616 0 10.1 4.123 10.1 9.86 0 5.737-4.484 9.899-10.1 9.899-5.617 0-10.1-4.162-10.1-9.9v.001zm25.296 1.778a4.066 4.066 0 014.08 4.08c0 2.223-1.816 4.04-4.08 4.04a4.053 4.053 0 01-4.04-4.04c0-2.262 1.82-4.08 4.04-4.08"
			/>
		</svg>
	)
}
