import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { Language, SeoQuery } from 'generated/sdk'
import { SeoProvider } from 'components/seo-provider/seo-provider'
import { DefaultSeo } from 'next-seo'
import { useLocationContext } from 'components/location-provider/location-provider'

type BaseSeoProps = {
	children: PropTypes.ReactNodeLike
	seoData?: SeoQuery['seo']
	allRss?: SeoQuery['allRss']
}

const SEO_TITLE = {
	[Language.Pt]: 'Portal de notícias do Porto.',
	[Language.En]: 'News Porto.',
}

const DEFAULT_SEO_DATA: (language: Language) => NonNullable<SeoQuery['seo']> = language => ({
	title: SEO_TITLE[language],
	description: '',
	appleItunesApp: '',
	googlePlayApp: '',
	facebookPageId: '',
	sharableImage: {
		title: '',
		rendition: {
			url: '',
		},
	},
	tags: [],
})

export const BaseSeo = ({ children, seoData, allRss }: BaseSeoProps) => {
	const { language, origin } = useLocationContext()
	const data = seoData || DEFAULT_SEO_DATA(language)
	const rss = allRss || []
	return (
		<SeoProvider seoData={data}>
			<Head>
				{rss.map(({ id, name }) =>
					id ? (
						<link
							key={id}
							rel="alternate"
							type="application/rss+xml"
							title={name}
							href={`${origin}/${language}/rss/${id}`}
						/>
					) : null
				)}
			</Head>
			<DefaultSeo
				title={data.title}
				additionalMetaTags={[
					{
						name: 'apple-itunes-app',
						content: `app-id=${data.appleItunesApp}`,
					},
					{
						name: 'google-play-app',
						content: `app-id=${data.googlePlayApp}`,
					},
					{
						name: 'author',
						content: 'CMP',
					},
					{
						name: 'distribution',
						content: 'global',
					},
					{
						name: 'fb:pages',
						content: data.facebookPageId,
					},
				]}
			/>
			{children}
		</SeoProvider>
	)
}
