import { NextPageContext } from 'next'
import Router from 'next/router'
import { notifyError } from 'services/sentry'
import { removeTrailingSlash } from './url'

export const redirect = async (ctx: NextPageContext, urlPath: string, statusCode = 302) => {
	const url = urlPath.startsWith('/') ? removeTrailingSlash(urlPath) : urlPath
	if (ctx.res) {
		ctx.res.writeHead(statusCode, { Location: url })
		ctx.res.end()
	} else {
		if (urlPath.startsWith('/')) {
			return Router.push(url).catch(error => notifyError(error))
		} else {
			document.location.href = url
			return
		}
	}
}

export const isLinkActive = (path: string, url: string, isExact: boolean) => {
	return isExact ? path === url : path.startsWith(url)
}
