import React, { Fragment } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { darkTheme } from 'theme/theme'
import { Logo } from 'components/logo/logo'
import { SvgFacebook } from 'components/svgs/svg-facebook'
import { SvgYouTube } from 'components/svgs/svg-youtube'
import { SvgTwitter } from 'components/svgs/svg-twitter'
import { SvgInstagram } from 'components/svgs/svg-instagram'
import { Text, Paragraph } from 'components/text/text'
import { LinkItem } from 'components/primary-navigation/primary-navigation'
import { SecondaryLinkItem } from 'components/secondary-navigation/secondary-navigation'
import { splitArrayIntoColumns } from 'utils/arrays'
import { borderRgbaWithHex, positionAbsoluteAndStretch, bgPortoPattern, ellipses, transition } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'

export type FooterDesktopProps = {
	social: {
		facebook?: string | null
		youtube?: string | null
		twitter?: string | null
		instagram?: string | null
	}
	primaryLinksTitle: string
	primaryLinks: LinkItem[]
	secondaryLinksTitle: string
	secondaryLinks: SecondaryLinkItem[]
	otherLinksTitle: string
	otherLinks: LinkItem[]
	copyright: string
	policiesLinks: LinkItem[]
}

const FooterDesktopWrapper = styled.div`
	position: relative;
	padding: 40px 0;
	background: ${props => props.theme.colors.twilightBlue};

	&::before {
		${positionAbsoluteAndStretch}
		content: ' ';
		${bgPortoPattern}
	}
`

const SocialLinks = styled.div`
	margin-top: 50px;

	a:not(:last-child) {
		margin-right: 20px;
	}
`

const LinksListWrapper = styled.div`
	${Paragraph} {
		margin: 2px 0 10px;
		font-size: 15px;
		font-weight: 700;
		line-height: 18px;
		text-transform: uppercase;
	}
`

const LinksList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;

	ul {
		padding: 0;
		margin: 0 0 16px;
		list-style: none;
		${props => borderRgbaWithHex('1px solid', props.theme.text.primary, 0.4, 'border-left')}
		li {
			padding-left: 14px;

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	${Paragraph} {
		font-size: 15px;
		font-weight: 700;
		line-height: 18px;
		text-transform: uppercase;
	}
`

const LinksListItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 12px;
	}

	ul {
		margin-top: 8px;
	}
`

const LinksListItemAnchor = styled.a<{ bold?: boolean }>`
	display: block;
	max-width: calc(100% - 24px);
	padding-top: 3px;
	font-size: 16px;
	font-weight: ${props => (props.bold ? 700 : 500)};
	line-height: 16px;
	color: ${props => props.theme.text.primary};
	opacity: 0.8;
	transition: ${transition('opacity')};
	${ellipses}

	&:hover {
		color: ${props => props.theme.text.primary};
		text-decoration: none;
		opacity: 1;
	}
`

const SecondaryLinksColumnsList = styled.nav`
	display: flex;

	> ul {
		width: 50%;
	}
`

const LinkAnchor = styled.a`
	padding-top: 3px;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.text.primary};
	opacity: 0.8;
	transition: ${transition('opacity')};

	&:hover {
		color: ${props => props.theme.text.primary};
		text-decoration: none;
		opacity: 1;
	}
`

const CopyrightWrapper = styled.div`
	margin-top: 40px;

	${Paragraph} {
		margin-bottom: 0;
		font-weight: 500;
		opacity: 0.8;
	}
`

const CopyrightLinks = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const CircleSeparator = styled.span`
	position: relative;
	display: inline-block;
	width: 28px;
	height: 19px;

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4px;
		height: 4px;
		content: ' ';
		background: ${props => props.theme.colors.white};
		border-radius: 50%;
		transform: translate(-50%, -50%);
	}
`

const LinkWrapper = ({ children, href }: { children: React.ReactNode; href: string }) => (
	<NextLink href={href} passHref>
		<LinkAnchor>{children}</LinkAnchor>
	</NextLink>
)

const FooterDesktopComponent = ({
	social,
	primaryLinks,
	primaryLinksTitle,
	secondaryLinks,
	secondaryLinksTitle,
	otherLinks,
	otherLinksTitle,
	copyright,
	policiesLinks,
}: FooterDesktopProps) => {
	const secondaryLinksColumns = splitArrayIntoColumns(secondaryLinks, 2, 'subLinks')

	return (
		<ThemeProvider theme={darkTheme}>
			<FooterDesktopWrapper>
				<div className="container">
					<div className="row">
						<div className="col-4">
							<Logo large suffix="-footer-desktop" />
							<SocialLinks>
								{social.facebook && (
									<LinkWrapper href={social.facebook}>
										<SvgFacebook suffix="-footer-desktop" />
									</LinkWrapper>
								)}
								{social.youtube && (
									<LinkWrapper href={social.youtube}>
										<SvgYouTube suffix="-footer-desktop" />
									</LinkWrapper>
								)}
								{social.twitter && (
									<LinkWrapper href={social.twitter}>
										<SvgTwitter suffix="-footer-desktop" />
									</LinkWrapper>
								)}
								{social.instagram && (
									<LinkWrapper href={social.instagram}>
										<SvgInstagram suffix="-footer-desktop" />
									</LinkWrapper>
								)}
							</SocialLinks>
						</div>
						<LinksListWrapper className="col-4">
							<Text>{secondaryLinksTitle}</Text>
							<SecondaryLinksColumnsList>
								{secondaryLinksColumns.map((column, idx) => (
									<LinksList key={idx}>
										{column.map((link, index) => (
											<LinksListItem key={index}>
												<NextLink href={link.href} passHref>
													<LinksListItemAnchor title={link.label} bold>
														{link.label}
													</LinksListItemAnchor>
												</NextLink>
												{link.subLinks && (
													<ul>
														{link.subLinks.map((subLink, i) => (
															<li key={i}>
																<NextLink href={subLink.href} passHref>
																	<LinksListItemAnchor title={subLink.label}>{subLink.label}</LinksListItemAnchor>
																</NextLink>
															</li>
														))}
													</ul>
												)}
											</LinksListItem>
										))}
									</LinksList>
								))}
							</SecondaryLinksColumnsList>
						</LinksListWrapper>
						<LinksListWrapper className="col-2">
							<Text>{primaryLinksTitle}</Text>
							<LinksList>
								{primaryLinks.map((link, index) => (
									<LinksListItem key={index}>
										<NextLink href={link.href} passHref>
											<LinksListItemAnchor title={link.label}>{link.label}</LinksListItemAnchor>
										</NextLink>
									</LinksListItem>
								))}
							</LinksList>
						</LinksListWrapper>
						<LinksListWrapper className="col-2">
							<Text>{otherLinksTitle}</Text>
							<LinksList>
								{otherLinks.map((link, index) => (
									<LinksListItem key={index}>
										<NextLink href={link.href} passHref>
											<LinksListItemAnchor title={link.label}>{link.label}</LinksListItemAnchor>
										</NextLink>
									</LinksListItem>
								))}
							</LinksList>
						</LinksListWrapper>
					</div>
					<CopyrightWrapper className="row">
						<div className="col-4">
							<Text>
								<span dangerouslySetInnerHTML={{ __html: copyright }} />
							</Text>
						</div>
						<div className="col-8">
							<CopyrightLinks>
								{policiesLinks.map((item, index) => (
									<Fragment key={index}>
										<LinkWrapper href={item.href}>{item.label}</LinkWrapper>
										{index < policiesLinks.length - 1 ? <CircleSeparator /> : null}
									</Fragment>
								))}
							</CopyrightLinks>
						</div>
					</CopyrightWrapper>
				</div>
			</FooterDesktopWrapper>
		</ThemeProvider>
	)
}

export const FooterDesktop = React.memo(FooterDesktopComponent)
