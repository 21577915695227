import React from 'react'
import styled, { css } from 'styled-components'
import { ActiveLink } from 'components/active-link/active-link'
import { boxShadowRgbaWithHex, transition } from 'theme/utils'
import { mediaBreakpointUpXl } from 'theme/breakpoints'
import { useActiveLink } from 'hooks/use-active-link'
import { useRouter } from 'next/router'

export type SecondaryLinkItem = {
	href: string
	label: string
	subLinks?: SecondaryLinkItem[]
}

export type SecondaryNavigationProps = {
	links: SecondaryLinkItem[]
}

const hoverLine = css`
	position: absolute;
	bottom: 0;
	left: 50%;
	width: calc(100% - 16px); /* minus anchor padding */
	height: 4px;
	pointer-events: none;
	content: ' ';
	background-color: ${props => props.theme.colors.twilightBlue};
	opacity: 0;
	transition: ${transition('opacity')};
	transform: translateX(-50%);
`

const SecondaryNavigationNavList = styled.ul`
	display: flex;
	justify-content: center;
	padding: 0;
	margin: 0;
	list-style: none;
`

const SecondaryNavigationNavItem = styled.li<{ active: boolean }>`
	position: relative;
	display: inline-block;
	margin-left: 8px;
	font-size: 0;

	${mediaBreakpointUpXl} {
		margin-left: 16px;
	}

	&:first-child {
		margin-left: 0;
	}

	a {
		position: relative;
		display: inline-block;
		padding: 16px 8px 11px;
		font-size: 14px;
		font-weight: 700;
		line-height: 1;
		color: ${props => props.theme.colors.twilightBlue};
		text-transform: uppercase;

		&::before {
			${hoverLine}
			opacity: ${props => (props.active ? 1 : 0)};
		}

		&::after {
			display: block;
			height: 0;
			overflow: hidden;
			font-weight: 700;
			visibility: hidden;
			content: attr(title);
		}

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;

			&::before {
				${hoverLine}
				opacity: 1;
			}
		}
	}

	& > ul {
		display: flex;
		flex-direction: column;
		visibility: hidden;
		opacity: 0;
		transition: ${transition('all')};
	}

	/* Menu Dropdown */
	&:hover {
		& > a {
			&::before {
				${hoverLine}
				opacity: 1;
			}
		}

		& > ul {
			visibility: visible;
			opacity: 1;
		}
	}
`

// Subnavigation menu styles
const SecondaryNavigationSubNavList = styled.ul`
	position: absolute;
	top: calc(100% + 1px); /* plus 1px border */
	left: 7px;
	z-index: 1;
	display: none;
	min-width: 180px;
	padding: 18px 0;
	margin: 0;
	list-style: none;
	background-color: ${props => props.theme.colors.white};
	border-radius: 4px;
	${props => boxShadowRgbaWithHex('0 5px 8px 0', props.theme.colors.black, 0.08)}

	/* Prevent menu to close when hovering on top of the menu border */
	&::before {
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		height: 1px;
		content: ' ';
		background: transparent;
	}
`

const SecondaryNavigationSubNavItem = styled.li<{ active: boolean }>`
	display: block;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		display: block;
		padding: 9px 25px 5px;
		font-size: 16px;
		font-weight: 700;
		color: ${props => (props.active ? props.theme.colors.darkishBlue : props.theme.colors.black)};
		text-transform: none;
		background-color: ${props => (props.active ? props.theme.colors.paleGrey : 'transparent')};
		transition: ${transition('color')}, ${transition('background-color')};

		&::before {
			content: none;
		}

		&:hover,
		&:focus,
		&:active {
			font-weight: 700;
			color: ${props => props.theme.colors.darkishBlue};
			background-color: ${props => props.theme.colors.paleGrey};

			&::before {
				content: none;
			}
		}
	}
`

const SubNavigationListItem = (item: SecondaryLinkItem, index: number) => {
	const isActive = useActiveLink(item.href)

	return (
		<SecondaryNavigationSubNavItem key={`${item.label}-${index}`} active={isActive}>
			<ActiveLink href={item.href} title={item.label}>
				{item.label}
			</ActiveLink>
		</SecondaryNavigationSubNavItem>
	)
}

const NavigationListItem = (item: SecondaryLinkItem, index: number) => {
	const isActive = useActiveLink(item.href)
	const hasSubLinks = item.subLinks && item.subLinks.length > 0

	return (
		<SecondaryNavigationNavItem key={`${item.label}-${index}`} active={isActive}>
			<ActiveLink href={item.href} title={item.label} {...(hasSubLinks && { 'aria-haspopup': 'true' })}>
				{item.label}
			</ActiveLink>

			{/* If has sub-links */}
			{item.subLinks && item.subLinks.length > 0 && (
				<SecondaryNavigationSubNavList aria-hidden="true" aria-expanded="false" aria-label={`${item.label} submenu`}>
					{item.subLinks.map(SubNavigationListItem)}
				</SecondaryNavigationSubNavList>
			)}
		</SecondaryNavigationNavItem>
	)
}

const SecondaryNavigationComponent = ({ links }: SecondaryNavigationProps) => {
	const router = useRouter()

	return (
		// Declare `key` to force a re-render and close the menu dropdown when the user taps on the screen
		<nav key={router.asPath}>
			<SecondaryNavigationNavList>{links.map(NavigationListItem)}</SecondaryNavigationNavList>
		</nav>
	)
}

export const SecondaryNavigation = React.memo(SecondaryNavigationComponent)
