import { useEffect } from 'react'
import Router from 'next/router'

export const useRouteChange = (callback: (url: string) => void) => {
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			callback(url)
		}

		Router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [callback])
}
