import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { isLinkActive } from 'utils/router'

export const useActiveLink = (href: string, isExact = false) => {
	const { asPath } = useRouter()
	const path = asPath.split('?')[0]
	const url = href.split('?')[0]

	const [isActive, setIsActive] = useState(() => isLinkActive(path, url, isExact))

	useEffect(() => {
		setIsActive(isLinkActive(path, url, isExact))
	}, [path, url, isExact])

	return isActive
}
