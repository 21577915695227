import React from 'react'
import PropTypes from 'prop-types'
import {
	PrimaryHeaderDesktop,
	PrimaryHeaderDesktopProps,
} from 'components/primary-header-desktop/primary-header-desktop'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { PrimaryHeaderMobile, PrimaryHeaderMobileProps } from 'components/primary-header-mobile/primary-header-mobile'
import styled from 'styled-components'
import { FooterDesktop, FooterDesktopProps } from 'components/footer-desktop/footer-desktop'
import { FooterMobile } from 'components/footer-mobile/footer-mobile'
import { Sticky } from 'components/sticky/sticky'

export type BaseLayoutProps = {
	children: PropTypes.ReactNodeLike
	headerProps: PrimaryHeaderDesktopProps & PrimaryHeaderMobileProps
	footerProps: FooterDesktopProps
}

const BaseLayoutWrapper = styled.div`
	position: relative;
	width: 100%;
`

const BaseLayoutMain = styled.main`
	position: relative;
	z-index: 1;
`

const BaseLayoutFooter = styled.footer`
	position: relative;
	z-index: 1;
`

export const BaseLayout = ({ children, headerProps, footerProps }: BaseLayoutProps) => {
	const { isMobile } = useMediaQueryContext()
	return (
		<BaseLayoutWrapper>
			<Sticky initialHeight={isMobile ? 89 : 106}>
				{isMobile ? <PrimaryHeaderMobile {...headerProps} /> : <PrimaryHeaderDesktop {...headerProps} />}
			</Sticky>
			<BaseLayoutMain>{children}</BaseLayoutMain>
			<BaseLayoutFooter>
				{isMobile ? <FooterMobile {...footerProps} /> : <FooterDesktop {...footerProps} />}
			</BaseLayoutFooter>
		</BaseLayoutWrapper>
	)
}
