import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { truncateText, transition } from 'theme/utils'

type TitleProps = {
	size?: number
	children: PropTypes.ReactNodeLike
	maxLines?: number
	dangerHtml?: boolean
}

const baseTitle = css<{ maxLines?: number; dangerHtml?: boolean }>`
	font-family: ${props => props.theme.fonts.primary};
	font-weight: ${props => (props.dangerHtml ? 400 : 700)};
	color: ${props => props.theme.text.primary};
	transition: ${transition('color')};
	${props => props.maxLines && truncateText(props.maxLines)}
`

const H1 = styled.h1`
	${baseTitle}
	font-size: 28px;

	${mediaBreakpointUpLg} {
		font-size: 40px;
	}
`

const H2 = styled.h2`
	${baseTitle}
	font-size: 24px;

	${mediaBreakpointUpLg} {
		font-size: 34px;
	}
`

const H3 = styled.h3`
	${baseTitle}
	font-size: 16px;

	${mediaBreakpointUpLg} {
		font-size: 30px;
	}
`

const H4 = styled.h4`
	${baseTitle}
	font-size: 16px;

	${mediaBreakpointUpLg} {
		font-size: 24px;
	}
`

const H5 = styled.h5`
	${baseTitle}
	font-size: 16px;
`

const H6 = styled.h6`
	${baseTitle}
	font-size: 16px;
`

export const Title = ({ size, children, dangerHtml, ...props }: TitleProps) => {
	const finalProps = {
		...props,
		dangerHtml,
		...(dangerHtml && { dangerouslySetInnerHTML: { __html: children as string } }),
	}
	switch (size) {
		case 2:
			return <H2 {...finalProps}>{dangerHtml ? null : children}</H2>
		case 3:
			return <H3 {...finalProps}>{dangerHtml ? null : children}</H3>
		case 4:
			return <H4 {...finalProps}>{dangerHtml ? null : children}</H4>
		case 5:
			return <H5 {...finalProps}>{dangerHtml ? null : children}</H5>
		case 6:
			return <H6 {...finalProps}>{dangerHtml ? null : children}</H6>
		default:
			return <H1 {...finalProps}>{dangerHtml ? null : children}</H1>
	}
}
