import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { SeoQuery } from 'generated/sdk'

export type SeoProviderProps = {
	children: PropTypes.ReactNodeLike
	seoData: SeoQuery['seo']
}
type ContextProps = NonNullable<SeoQuery['seo']>

const defaultState = {
	title: 'Portal de notícias do Porto.',
	description: '',
	appleItunesApp: '',
	googlePlayApp: '',
	facebookPageId: '',
	sharableImage: {
		title: '',
		rendition: {
			url: '',
		},
	},
	tags: [],
}

export const SeoContext = createContext<ContextProps>({
	...defaultState,
})

export const SeoProvider = ({ children, seoData }: SeoProviderProps) => {
	const value = useMemo(() => ({ ...defaultState, ...seoData }), [seoData])

	return <SeoContext.Provider value={value}>{children}</SeoContext.Provider>
}

export const useSeoContext = () => useContext(SeoContext)
